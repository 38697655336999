import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Image from "./Image"

const ImageThumbnail = ({ isActive, onClick, image }) => {
  const handleClick = () => {
    onClick(image)
  }

  return (
    <Wrapper onClick={handleClick} isActive={isActive}>
      <Image image={image.gatsbyImageData} alt="" />
    </Wrapper>
  )
}

export default ImageThumbnail
