import styled from "styled-components"

const Availability = styled.li`
  list-style-type: none;
  margin-bottom: 24px;

  color: ${props => (props.availableForSale ? "#01933B" : "red")};

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-bottom: 0px;
  }
`

export default Availability
