import styled from "styled-components"

const CounterButton = styled.button`
  color: ${props => props.theme.colors.text.white};
  background: ${props => props.theme.colors.background.main};
  height: 100%;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 0px;

  :focus {
    outline: none;
  }
`

export default CounterButton
