import styled from "styled-components"

const Quantity = styled.div`
  display: inline-block;
  background: #2d2d2d;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  flex: 1 0 auto;

  @media (min-width: 1350px) {
    font-size: 36px;
    line-height: 50px;
  }

  @media (min-width: 1600px) {
    font-size: 42px;
    line-height: 70px;
  }
`

export default Quantity
