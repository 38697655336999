import styled from "styled-components"

const ImageWrapper = styled.div`
  cursor: pointer;

  .gatsby-image-wrapper {
    max-height: 350px;
  }
`

export default ImageWrapper
