import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//Styled components imports
import Background from "./Background"
import Wrapper from "./Wrapper"
import Link from "./Link"
import Button from "../../shared-styled-components/Button.js"
import ButtonsWrapper from "./ButtonsWrapper"

const AddedToCartModal = ({ product, close }) => {
  const intl = useIntl()

  return (
    <Background>
      <Wrapper>
        <h5>
          {intl.formatMessage({
            id: "add_to_cart.added_to_cart",
          })}
        </h5>
        <h3>{product.title}</h3>

        <ButtonsWrapper>
          <Link to="/kosik">
            {intl.formatMessage({
              id: "add_to_cart.shopping_cart",
            })}
          </Link>
          <Button onClick={close}>
            {intl.formatMessage({
              id: "add_to_cart.continue_shopping",
            })}
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </Background>
  )
}

export default AddedToCartModal
