import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = styled(GatsbyImage)`
  img {
    object-fit: contain !important;
  }
`

export default Image
