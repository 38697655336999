import styled from "styled-components"

const Price = styled.div`
  font-size: 24px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: center;
    font-size: 36px;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 42px;
    margin-left: 16px;
    text-align: left;
  }

  @media (min-width: 1350px) {
    margin-left: 32px;
  }

  @media (min-width: 1600px) {
    margin-left: 64px;
  }
`

export default Price
