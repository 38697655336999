import React, { useState } from "react"
import { SRLWrapper } from "simple-react-lightbox"

//Swiper imports
import Swiper from "react-id-swiper"

//React components imports
import ImageThumbnail from "./ImageThumbnail"

//Styled components imports
import Wrapper from "./Wrapper"
import ImageWrapper from "./ImageWrapper"
import ThumbnailsWrapper from "./ThumbnailsWrapper"
import Image from "./Image"
import HiddenWrapper from "./HiddenWrapper"

//SVG imports
import ChevronIcon from "../../shared-styled-components/ChevronIcon"

const ImageGallery = ({ images }) => {
  const [activeImageThumbnail, setActiveImageThumbnail] = useState(images[0])

  const options = {
    settings: {
      hideControlsAfter: true,
    },
    buttons: {
      showCloseButton: false,
      showNextButton: false,
      showPrevButton: false,
      showAutoplayButton: false,
      showThumbnailsButton: false,
    },
  }

  const params = {
    slidesPerView: "auto",
    spaceBetween: 15,
    allowTouchMove: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev">
        <ChevronIcon size="30" style={{ transform: "rotate(180deg)" }} />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next">
        <ChevronIcon size="30" />
      </button>
    ),
  }

  return (
    <Wrapper>
      <SRLWrapper options={options}>
        <ImageWrapper>
          <Image image={activeImageThumbnail?.gatsbyImageData} alt="" />
          <HiddenWrapper>
            {images.map(image => {
              if (activeImageThumbnail.id === image.id) {
                return <> </>
              } else {
              }
              return (
                <div key={image.id}>
                  <ImageThumbnail
                    image={image}
                    isActive={activeImageThumbnail.id === image.id}
                    onClick={() => {
                      setActiveImageThumbnail(image)
                    }}
                  />
                </div>
              )
            })}
          </HiddenWrapper>
        </ImageWrapper>
      </SRLWrapper>
      <ThumbnailsWrapper>
        <Swiper {...params}>
          {images.map(image => {
            return (
              <div key={image.id}>
                <ImageThumbnail
                  image={image}
                  isActive={activeImageThumbnail.id === image.id}
                  onClick={() => {
                    setActiveImageThumbnail(image)
                  }}
                />
              </div>
            )
          })}
        </Swiper>
      </ThumbnailsWrapper>
    </Wrapper>
  )
}

export default ImageGallery
