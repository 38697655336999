import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  max-width: 960px;
  position: relative;
  z-index: 999;
  background-color: #000000;
  border: 2px solid #ffffff;
  margin: 0 32px;
  padding: 25px;
`

export default Wrapper
