import React, { useState, useEffect, useContext } from "react"
import { useIntl } from "gatsby-plugin-react-intl"

import { Media } from "../../utils/Media"

//Context imports
import CartContext from "../../context/CartContext"

//Gatsby imports
import { graphql } from "gatsby"

//API imports
import { getProductQuantity } from "../../lib/api"

//React components imports
import Layout from "../../components/Layout"
import ImageGallery from "../../components/ImageGallery"
import ImageSwiper from "../../components/ImageSwiper"
import Breadcrumbs from "../../components/Breadcrumbs"
import ProductInfo from "./ProductInfo"

//Styled components imports
import Container from "./Container"
import Title from "./Title"
import Price from "./Price"
import Description from "./Description"
import DescriptionTitle from "./DescriptionTitle"
import DescriptionText from "./DescriptionText"
import DesktopContainer from "./DesktopContainer"
import AddToCartButton from "./AddToCartButton"
import CounterAndButtonContainer from "./CounterAndButtonContainer"
import Counter from "./Counter"
import CounterButton from "./CounterButton"
import Quantity from "./Quantity"
import SelectBox from "./SelectBox"
import MinusIcon from "../../shared-styled-components/MinusIcon"
import PlusIcon from "../../shared-styled-components/PlusIcon"
import AddedToCartModal from "../../components/AddedToCartModal"

const ProductTemplate = ({ data, location, pageContext }) => {
  const intl = useIntl()
  console.log("DATa", data)
  const getProduct = () => {
    if (intl.locale === "en") {
      return pageContext.productEn
    } else {
      return pageContext.productCs
    }
  }

  const localeProduct = getProduct()

  const { getProductById, updateLineItem } = useContext(CartContext)
  const [showMessage, setShowMessage] = useState(false)
  const [product, setProduct] = useState(null)
  const [variant, setVariant] = useState(data.shopifyProduct.variants[0])
  const [quantity, setQuantity] = useState(1)
  const [availableQuantity, setAvailableQuantity] = useState(1)

  console.log("VARIANT", variant)
  useEffect(() => {
    getProductById(data.shopifyProduct.shopifyId)
      .then(async res => {
        setProduct(res)
        console.log("RES", res)
        setVariant(
          res.variants.length && {
            availableForSale: res.variants[0].available,
            id: res.variants[0].id,
            price:
              res.variants[0].priceV2?.amount || res.variants[0].price?.amount,
            weight: res.variants[0].weight,
          }
        )

        setAvailableQuantity(
          await getProductQuantity(res.handle, res.variants[0].id)
        )
      })
      .catch(err => {
        console.log("ERR")
        console.log(err)
      })

    // Set states for gtag events
    if (typeof window.gtag !== "undefined") {
      window.gtag("event", "view_item", {
        items: [
          {
            item_name: data.shopifyProduct.title,
            currency: "CZK",
          },
        ],
      })
    }
  }, [
    data.shopifyProduct.shopifyId,
    data.shopifyProduct.title,
    getProductById,
    setProduct,
  ])

  const handleAddToCartClick = () => {
    setShowMessage(true)
    updateLineItem({
      variantId: variant.id,
      quantity,
      customAttributes: [
        { key: "description", value: data.shopifyProduct.description },
        { key: "localeTitle", value: localeProduct.title },
      ],
    })
    // Add to cart gtag event
    if (typeof window.gtag !== "undefined") {
      const productPrice = variant.priceV2.amount
      const categories = data.shopifyCollection?.title.split("/")
      const categoriesLength = categories.length
      const category = categories[categoriesLength - 1]
      const productObj = {
        item_id: variant.id,
        item_name: data.shopifyProduct.title,
        currency: "CZK",
        item_category: category,
        item_variant:
          variant.title === "Default Title" ? "none" : variant.title,
        price: variant.priceV2.amount,
        quantity: quantity,
      }
      window.gtag("event", "add_to_cart", {
        value: productPrice,
        items: [productObj],
      })
    }
  }

  return (
    <Layout subtitle={localeProduct.title}>
      {showMessage && (
        <AddedToCartModal
          product={localeProduct}
          close={() => setShowMessage(false)}
        />
      )}
      <Breadcrumbs
        data={{
          path: "/produkty" + location.pathname.replace("/en", ""),
          breadEnd: localeProduct.title,
          collection: {
            title: data.shopifyCollection?.title,
            handle: data.shopifyCollection?.handle,
          },
        }}
      />
      <Media lessThan="tablet">
        <ImageSwiper images={data.shopifyProduct.images} />
        <Title>{localeProduct.title}</Title>
        <Price>{variant.price} Kč</Price>
        <ProductInfo
          availableForSale={variant?.availableForSale}
          weight={variant.weight}
          selectedOptions={variant.selectedOptions}
        />
        {console.log("PRODUCT VARIANT", product?.variants)}
        {product?.variants.length > 1 && (
          <SelectBox
            onChange={e => {
              setVariant({
                availableForSale: product.variants[e.target.value].available,
                id: product.variants[e.target.value].id,
                price:
                  product.variants[e.target.value].priceV2?.amount ||
                  product.variants[e.target.value].price?.amount,
                weight: product.variants[e.target.value].weight,
              })
            }}
          >
            {product?.variants.map((variant, index) => (
              <option value={index}>{variant.title}</option>
            ))}
          </SelectBox>
        )}
        <Description>
          <CounterAndButtonContainer>
            <Counter>
              <CounterButton
                onClick={() => {
                  if (quantity > 0) {
                    setQuantity(quantity - 1)
                  }
                }}
              >
                <MinusIcon size="32" />
              </CounterButton>

              <Quantity>{quantity}</Quantity>
              <CounterButton
                onClick={() =>
                  quantity < availableQuantity
                    ? setQuantity(quantity + 1)
                    : null
                }
              >
                <PlusIcon size="32" />
              </CounterButton>
            </Counter>
            <AddToCartButton
              onClick={handleAddToCartClick}
              disabled={!variant?.availableForSale}
            >
              {intl.formatMessage({ id: "product.add_to_cart" })}
            </AddToCartButton>
          </CounterAndButtonContainer>
          <DescriptionTitle>
            {intl.formatMessage({ id: "product.description" })}
          </DescriptionTitle>
          <DescriptionText>
            <div
              dangerouslySetInnerHTML={{
                __html: localeProduct.descriptionHtml,
              }}
            ></div>
          </DescriptionText>
        </Description>
      </Media>

      <Media greaterThanOrEqual="tablet">
        <DesktopContainer>
          <ImageGallery images={data.shopifyProduct.images} />
          <Container>
            <Title>{localeProduct.title}</Title>
            <ProductInfo
              availableForSale={variant?.availableForSale}
              weight={variant.weight}
              selectedOptions={variant.selectedOptions}
            />
            {product?.variants.length > 1 && (
              <SelectBox
                onChange={e => {
                  setVariant({
                    availableForSale:
                      product.variants[e.target.value].available,
                    id: product.variants[e.target.value].id,
                    price:
                      product.variants[e.target.value].priceV2?.amount ||
                      product.variants[e.target.value].price?.amount,
                    weight: product.variants[e.target.value].weight,
                  })
                }}
              >
                {product?.variants.map((variant, index) => (
                  <option value={index}>{variant.title}</option>
                ))}
              </SelectBox>
            )}
            <div style={{ marginTop: "auto" }}>
              <Price>{variant.price} Kč</Price>
              <CounterAndButtonContainer>
                <Counter>
                  <CounterButton
                    onClick={() => {
                      if (quantity > 0) {
                        setQuantity(quantity - 1)
                      }
                    }}
                  >
                    <MinusIcon size="32" />
                  </CounterButton>
                  <Quantity>{quantity}</Quantity>
                  <CounterButton
                    onClick={() =>
                      quantity < availableQuantity
                        ? setQuantity(quantity + 1)
                        : null
                    }
                  >
                    <PlusIcon size="32" />
                  </CounterButton>
                </Counter>
                <AddToCartButton
                  onClick={handleAddToCartClick}
                  disabled={!variant?.availableForSale}
                >
                  {intl.formatMessage({ id: "product.add_to_cart" })}
                </AddToCartButton>
              </CounterAndButtonContainer>
            </div>
          </Container>
        </DesktopContainer>
        <Description>
          <DescriptionTitle>
            {intl.formatMessage({ id: "product.description" })}
          </DescriptionTitle>
          <DescriptionText>
            <div
              dangerouslySetInnerHTML={{
                __html: localeProduct.descriptionHtml,
              }}
            ></div>
          </DescriptionText>
        </Description>
      </Media>
    </Layout>
  )
}

export default ProductTemplate

export const query = graphql`
  query ProductQuery($shopifyId: String) {
    shopifyProduct(shopifyId: { eq: $shopifyId }) {
      shopifyId
      title
      description
      descriptionHtml
      variants {
        id
        weight
        price
        availableForSale
      }
      images {
        id
        gatsbyImageData(width: 600)
      }
      collections {
        title
      }
    }
    shopifyProductVariant(shopifyId: { eq: $shopifyId }) {
      availableForSale
      price
    }
    shopifyCollection(
      products: { elemMatch: { shopifyId: { eq: $shopifyId } } }
    ) {
      title
      handle
    }
  }
`
