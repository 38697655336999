import styled from "styled-components"

const ThumbnailsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  max-width: 100%;
  position: relative;
`

export default ThumbnailsWrapper
