import styled from "styled-components"

const CounterAndButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-top: 16px;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-top: 42px;
    justify-content: space-between;
    max-width: 320px;
    margin-left: 16px;
    margin-right: 16px;
  }

  @media (min-width: 1350px) {
    margin-left: 32px;
    max-width: 410px;
  }

  @media (min-width: 1600px) {
    margin-left: 64px;
  }
`

export default CounterAndButtonContainer
