import styled from "styled-components"
import Select from "../../shared-styled-components/Select"

const SelectBox = styled(Select)`
  margin: 0 10px 20px;
  width: auto;

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin: 0 15px 5px;
  }

  @media (min-width: 1350px) {
    margin: 0 64px 5px;
  }
`

export default SelectBox
