import styled from "styled-components"

const Counter = styled.div`
  border: 2px solid #2d2d2d;
  display: flex;
  width: 100px;
  align-items: center;
  margin-right: 36px;
  border-radius: 5px;
  width: 100px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin: 0;
  }

  @media (min-width: 1350px) {
    width: 145px;
  }
`

export default Counter
