import React from "react"

//Gatsby Image import
import { GatsbyImage as Image } from "gatsby-plugin-image"

//Swiper imports
import Swiper from "react-id-swiper"

const ImageSwipper = ({ images }) => {
  const params = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  }

  return (
    <>
      <Swiper {...params}>
        {images.map(image => {
          return <Image image={image.gatsbyImageData} />
        })}
      </Swiper>
    </>
  )
}

export default ImageSwipper
