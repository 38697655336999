import styled, { css } from "styled-components"

const disabled = css`
  color: ${props => props.theme.colors.text.darkGrey};

  &:hover {
    color: ${props => props.theme.colors.text.darkGrey};
    background: #2d2d2d;
  }
`

const AddToCartButton = styled.button`
  font-size: 17px;
  text-transform: uppercase;
  padding: 10px 5px;
  background: #2d2d2d;
  border: none;
  color: ${props => props.theme.colors.text.white};
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    color: black;
    background: white;
  }

  @media (min-width: 1350px) {
    font-size: 20px;
    padding: 12px 10px;
  }

  @media (min-width: 1600px) {
    font-size: 24px;
    padding: 20px 10px;
  }

  ${props => props.disabled && disabled}
`

export default AddToCartButton
