import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//Styled components imports
import Wrapper from "./Wrapper"
import Availability from "./Availability"
import Weight from "./Weight"

const ProductInfo = ({ weight, availableForSale, selectedOptions }) => {
  const intl = useIntl()
  const getAvailability = () => {
    if (availableForSale) {
      return <>{intl.formatMessage({ id: "product.stock.available" })}</>
    } else if (!availableForSale) {
      if (availableForSale === undefined) {
        return ""
      } else {
        return <>{intl.formatMessage({ id: "product.stock.not_available" })}</>
      }
    }
    return
  }
  return (
    <Wrapper>
      <Availability availableForSale={availableForSale}>
        {getAvailability()}
      </Availability>
      {weight > 0 && <Weight>{`Hmotnost (gramy): ${weight}`}</Weight>}
      {selectedOptions?.length > 0 &&
        selectedOptions
          .filter(option => {
            return option.value !== "Default Title"
          })
          .map(option => {
            return <li>{option.value}</li>
          })}
    </Wrapper>
  )
}

export default ProductInfo
