import styled from "styled-components"

const ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 65px;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
`

export default ButtonsWrapper
