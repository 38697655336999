import styled from "styled-components"

const Title = styled.h1`
  font-size: 22px;

  margin-top: 20px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 36px;
    margin-bottom: 48px;
  }
`

export default Title
