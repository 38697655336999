import styled from "styled-components"

const DesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 35px;
  max-width: 100vw;
  margin-bottom: 42px;
`

export default DesktopContainer
