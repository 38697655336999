import styled from "styled-components"

const Wrapper = styled.div`
  cursor: pointer;
  border: ${props =>
    props.isActive ? "1px solid grey" : "1px solid transparent"};
  width: 160px;
  height: 107px;
`

export default Wrapper
