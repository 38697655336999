import styled from "styled-components"

const Select = styled.select`
  border: 1px solid ${props => props.theme.colors.text.white};
  color: ${props => props.theme.colors.text.white};
  border-radius: 5px;
  background: transparent;
  width: 100%;
  padding: 8px 12px;

  option {
    color: initial;
  }
`

export default Select
