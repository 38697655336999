import styled from "styled-components"

const Wrapper = styled.ul`
  font-size: 16px;
  list-style-type: disc;
  margin-bottom: 32px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 20px;
    margin-left: 64px;
  }
`

export default Wrapper
