import styled from "styled-components"

const DescriptionTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 24px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 24px;
    margin-bottom: 24px;
    margin-top: 0px;
  }
`

export default DescriptionTitle
